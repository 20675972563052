<template>
  <div class="number-input-container">
    <an-tooltip :text="minErrorTooltipText" position="bottom-left" :disabled="!minErrorTooltipText">
      <div class="clickable-icon" @click="subtract">
        <svg-icon name="circle-minus" :size="24" />
      </div>
    </an-tooltip>
    <transition name="slide-fade" mode="out-in">
      <div class="number" :key="displayValue">
        {{ displayValue }}
      </div>
    </transition>
    <div class="clickable-icon" @click="add">
      <svg-icon name="circle-plus" :size="24" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'an-number-input',
  props: {
    value: {
      type: Number,
      required: true
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: Infinity
    },
    step: {
      type: Number,
      default: 1,
      validator: (n) => n > 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minErrorTooltipText: {
      type: String
    }
  },
  mounted() {
    const val = Math.max(this.min, Math.min(this.value, this.max));
    this.$emit('input', val);
  },
  computed: {
    displayValue() {
      return String(this.value).padStart(2, '0');
    }
  },
  methods: {
    subtract() {
      if (this.disabled) return;

      const { min, value, step } = this;
      const newValue = Math.max(min, value - step);
      if (value - step < min) {
        this.$emit('subtract-blocked');
      }
      this.$emit('input', newValue);
      this.$emit('subtract', { value, newValue });
    },
    add() {
      if (this.disabled) return;

      const { max, value, step } = this;
      const newValue = Math.min(max, value + step);
      this.$emit('input', newValue);
      this.$emit('add', { value, newValue });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins.scss';

.clickable-icon {
  opacity: 0.3;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 1;
    background: none;
  }
}

.number-input-container {
  display: flex;
  align-items: center;
}

.number {
  @include secondary-title;
  text-align: center;
  font-size: 24px;
  line-height: 36px;
  margin: 0 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 32px;
}
</style>
