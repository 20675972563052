import Vue from 'vue';
// import VueSlider from 'vue-slider-component';
import Banner from '@/components/Banners/Banner.vue';
import Button from '@/components/Button/Button.vue';
import Link from '@/components/Link/Link.vue';
import Card from '@/components/Card/Card.vue';
import Input from '@/components/Input/Input.vue';
import NumberInput from '@/components/Input/NumberInput.vue';
import Select from '@/components/Select/Select.vue';
import Stepper from '@/components/Stepper/Stepper.vue';
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue';
import AnimaIcon from '@/components/SvgIcon/AnimaIcon.vue';
import Emoji from '@/components/SvgIcon/Emoji.vue';
import UserAvatar from '@/components/Avatar/UserAvatar.vue';
import Textarea from '@/components/Textarea/Textarea.vue';
import Toggle from '@/components/Toggle/Toggle.vue';
import Checkbox from '@/components/Checkbox/Checkbox.vue';
import Radio from '@/components/Radio/Radio.vue';
import Note from '@/components/Note/Note.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import Logo from '@/components/Logo/Logo.vue';
import TooltipBig from '@/components/TooltipBig/TooltipBig.vue';
import Prompt from '@/components/Prompt/Prompt.vue';
import InilineLoader from '@/components/Loading/InlineLoader';
import AccountModal from '@/components/Account/Account';
import DevicesLimitReachedModal from '@/views/DevicesLimitReached.vue';
import NotificationToast from '@/components/Notifications/NotificationToast';

import DeleteAccountModal from '@/components/Account/DeleteAccount';

import NewProjectModal from '@/components/Team/NewProject.vue';
import NewTeamModal from '@/components/Team/NewTeam.vue';
import DeleteProjectModal from '@/components/Team/DeleteProject.vue';
import ArchiveProjectModal from '@/components/Team/ArchiveProject.vue';
import MoveProjectModal from '@/components/Team/MoveProject.vue';

import TeamSettingsGeneralModal from '@/components/Team/Settings/General.vue';
import TeamSettingsMembersModal from '@/components/Team/Settings/Members.vue';
import TeamSettingsMembersManagementModal from '@/components/Team/Settings/MembersManagement.vue';
import TeamSettingsPermissionsModal from '@/components/Team/Settings/Permissions.vue';
import TeamSettingsBillingModal from '@/components/Team/Settings/Billing.vue';
import TeamSettingsDeleteModal from '@/components/Team/Settings/Delete.vue';
import TeamPartialAccess from '@/components/Team/PartialAccess.vue';

import ProjectInvite from '@/components/Project/Invite/Invite.vue';
import ProjectRequestAccess from '@/components/Project/RequestAccess.vue';
import ProjectSettings from '@/components/Project/Settings/Settings.vue';
import WhatsNext from '@/components/Project/WhatsNext/WhatsNext.vue';

import PaymentSuccessModal from '@/components/Payment/Success.vue';
import ConnectSlackModal from '@/views/ConnectSlack.vue';
import PluginsModal from '@/views/Plugins';
import DesignImportModal from '@/views/DesignImport.vue';
import PricingModal from '@/views/Pricing';
import PaymentModal from '@/views/Payment';
import PaymentModalStigg from '@/views/PaymentStigg';
import DowngradeModal from '@/views/Downgrade/Downgrade';
import CancelPlanModal from '@/views/Downgrade/CancelPlan';
import SwitchToDesktopModal from '@/views/SwitchToDesktop.vue';
import UpgradeTeamPlanModal from '@/views/UpgradeTeamPlanModal.vue';
import ContactAnimaModal from '@/views/ContactAnimaModal.vue';
import LearnVideoModal from '@/components/Learn/VideoModal.vue';
import PersonalizedOnboarding from '@/components/PersonalizedOnboarding/Panel.vue';
import ViewerAccessModal from '@/components/Project/ViewerAccess.vue';
import UpgradePlanModal from '@/views/UpgradePlanModal.vue';

import ExportCodeAsViewer from '@/components/OmniView/ExportCodeAsViewer.vue';
import PackageIsReadyModal from '@/components/OmniView/PackageIsReadyModal.vue';
import PlanChangesModal from '@/components/Project/PlanChangesModal.vue';
import PackageFailedModal from '@/components/OmniView/PackageFailedModal.vue';
import WaitingForPackageModal from '@/components/OmniView/WaitingForPackageModal.vue';
import UpdatePaymentModal from '@/components/Payment/UpdatePaymentModal.vue';

import AppStatusBanner from '@/components/Banners/AppStatusBanner';
import UpgradeTeamPlanBanner from '@/components/Banners/UpgradeTeamPlanBanner';
import ScreenLimitReachedBanner from '@/components/Banners/ScreenLimitReachedBanner';
import StorybookBetaBanner from '@/components/Banners/StorybookBetaBanner';
import CommunitySurveyBanner from '@/components/Banners/CommunitySurveyBanner';
import PlanUpgradeNeededBanner from '@/components/Banners/PlanUpgradeNeededBanner.vue';
import UpdatePaymentBanner from '@/components/Banners/UpdatePaymentBanner.vue';

// import shared components and add them to the array

const sharedComponents = [
  Banner,
  Button,
  Card,
  Input,
  NumberInput,
  Select,
  Stepper,
  SvgIcon,
  AnimaIcon,
  Emoji,
  Link,
  UserAvatar,
  Textarea,
  Toggle,
  Checkbox,
  Radio,
  Note,
  Tooltip,
  TooltipBig,
  Logo,
  Prompt,
  InilineLoader,
  NotificationToast
];
const modalComponents = [
  PaymentSuccessModal,
  AccountModal,
  DeleteAccountModal,
  NewProjectModal,
  MoveProjectModal,
  NewTeamModal,
  ArchiveProjectModal,
  DeleteProjectModal,
  TeamSettingsGeneralModal,
  TeamSettingsMembersModal,
  TeamSettingsMembersManagementModal,
  TeamSettingsPermissionsModal,
  TeamSettingsBillingModal,
  TeamSettingsDeleteModal,
  TeamPartialAccess,
  ViewerAccessModal,
  ProjectInvite,
  ProjectRequestAccess,
  ProjectSettings,
  WhatsNext,
  PluginsModal,
  PricingModal,
  PaymentModal,
  PaymentModalStigg,
  DowngradeModal,
  CancelPlanModal,
  SwitchToDesktopModal,
  UpgradeTeamPlanModal,
  ContactAnimaModal,
  ConnectSlackModal,
  ExportCodeAsViewer,
  PackageIsReadyModal,
  PlanChangesModal,
  PackageFailedModal,
  WaitingForPackageModal,
  LearnVideoModal,
  DesignImportModal,
  PersonalizedOnboarding,
  DevicesLimitReachedModal,
  UpgradePlanModal,
  UpdatePaymentModal
];
const bannerComponents = [
  AppStatusBanner,
  UpgradeTeamPlanBanner,
  ScreenLimitReachedBanner,
  StorybookBetaBanner,
  CommunitySurveyBanner,
  PlanUpgradeNeededBanner,
  UpdatePaymentBanner
];

export const registerSharedComponents = () => {
  sharedComponents.forEach((component) => Vue.component(component.name, component));
};

export const registerModalComponents = () => {
  modalComponents.forEach((component) => Vue.component(component.name, component));
};

export const registerBannerComponents = () => {
  bannerComponents.forEach((component) => Vue.component(component.name, component));
};

// external components

// Vue.component('VueSlider', VueSlider);
