<template>
  <div class="container" data-cy="team-members-invite-modal">
    <div class="title">Revoke contributor privileges</div>
    <span class="subtitle"
      >Each contributor role in your team requires a paid seat.<br />Consider before limiting contributor access.</span
    >
    <div class="body">
      <MembersForm hideInviteInput />
      <div class="cta">
        <an-button @click="done"> Done </an-button>
      </div>
    </div>
  </div>
</template>

<script>
import MembersForm from '@/components/Team/Settings/MembersForm';

export default {
  name: 'team-members-management',
  props: {
    isOnboardingFlow: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MembersForm
  },
  computed: {
    theme() {
      return this.isOnboardingFlow ? 'dark' : 'light';
    }
  },
  methods: {
    done() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';

.container {
  margin-top: 64px;
}

.container::after {
  height: 32px;
}

.body {
  margin-top: 0px;
  margin-bottom: 0px;
}

.cta {
  margin-top: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
